import { TemplateSetup } from '../types/template.type';
import { getTemplateData } from './data.helpers';

class TemplateVersionHelpers {
    static LAYERS_NOT_REVERSE_VERSION = 2; // The version where layers should be reversed.
    static SIZE_AUTO_VERSION = 3; // The version where size should be fit-content.
    static NEVER_SHOW_CHILDREN_VERSION = 4; // The version where children should always be shown.
    static SHOW_NEW_PREDEF_ANIMATIONS_VERSION = 6; // The version where children should always be shown.
    static SHOULD_USE_OBJECT_FOR_FEED_SELECTOR_VERSION = 7; // The version where the feed items are in an object so inputs in the CB work.
    static SHOULD_USE_DISPLAY_BLOCK_FOR_GRADIENT_TEXT_VERSION = 8; // The version where the feed items are in an object so inputs in the CB work.
    static SHOULD_SHOW_BASE_FRAME_ABOVE = 9; // The version where the base frame should be above the main frame format styling.
    static DISABLE_MULTILANGUAGE_FOR_ALL_FEEDMAPPED = 10; // The version where the base frame should be above the main frame format styling.
    static SHOULD_CHECK_PREVIOUS_KEYFRAMES_AND_COMBINE = 11; // In previous versions when combining custom and predefined animations of type: position, rotation, opacity and scale there were some conflicts. This version fixes that.
    static SHOULD_NOT_RENDER_ANIMATIONS_IF_DISABLED = 12; // Before this version when animations were disabled but keyframes already existed, the last keyframe of the animations would be visible. This version does not render the animations if they are disabled.
    static MEDIA_LAYERS_SHOULD_END_LATER = 13; // In previous versions, when the video start time was greater then 0 (visibility bar), the video would still stop at the same time as when the video started at 0. This version fixes that.
    static SHOULD_USE_EXPORT_KEYFRAMES_ONMOUNT = 14; // When template designer first loads in, use the keyframes from the export for the first time. Then it doesn't need to be generated. In a new version because before this the calculation of keyframes was wrong. And then it showed the wrong animation.

    /**
     * Gets the template version.
     * @returns The template version.
     */
    static getTemplateVersion = (): TemplateSetup['templateVersion'] => {
        return getTemplateData<TemplateSetup['templateVersion']>('templateSetup.templateVersion');
    };

    /**
     * After 23-09-2022, we use a new way of sizing fonts. The new templates only use font scaling via 'em' and percentages
     * @param createdDate - The creation date of the template
     */
    static templateUsesFontScaling = (createdDate: Date | string): boolean => {
        return !!(createdDate && new Date(createdDate) > new Date('2022-09-23'));
    };

    /**
     * Checks if layers should be reversed.
     * The layers should be reversed for old templates.
     * @returns If the layers should be reversed.
     */
    static layersShouldNotReverse = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.LAYERS_NOT_REVERSE_VERSION;
    };

    /**
     * Checks if the size should be fit-content if no size is filled in.
     * @returns If the size should be fit-content.
     */
    static shouldSizeBeAuto = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SIZE_AUTO_VERSION;
    };

    /**
     * Check template version if older then always show children layers else show children layers only if parent is visible
     * @returns If the size should be fit-content.
     */
    static shouldHideChildren = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.NEVER_SHOW_CHILDREN_VERSION;
    };

    /**
     * Check template version if older then show some older predefined animations because the some keyframes changed
     * @returns If old animations should be shown.
     */
    static shouldShowNewPredefinedAnimations = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOW_NEW_PREDEF_ANIMATIONS_VERSION;
    };

    /**
     * Check template version if older then use object for feed selector
     * @returns If the feed selector should use an object.
     */
    static shouldUseObjectForFeedSelector = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_USE_OBJECT_FOR_FEED_SELECTOR_VERSION;
    };

    /**
     * Check template version if older then use display block for gradient text
     * @returns If the gradient text should use display block.
     */
    static shouldUseDisplayBlockForGradientText = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_USE_DISPLAY_BLOCK_FOR_GRADIENT_TEXT_VERSION;
    };

    /**
     * Check template version if older then show base frame above
     * @returns If the base frame should be above the main frame format styling.
     */
    static shouldShowBaseFrameAbove = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_SHOW_BASE_FRAME_ABOVE;
    };

    /**
     * Check template version if older then only remove multilanguage from input if there is also a feed selector.
     * @returns If the base frame should be above the main frame format styling.
     */
    static shouldDisableMultilanguageForAllFeedMapped = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.DISABLE_MULTILANGUAGE_FOR_ALL_FEEDMAPPED;
    };

    /**
     * In previous versions when combining custom and predefined animations of type: position, rotation, opacity and scale there were some conflicts. This version fixes that.
     * @returns If the keyframes should be checked and combined.
     */
    static shouldCheckPreviousKeyframesAndCombine = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_CHECK_PREVIOUS_KEYFRAMES_AND_COMBINE;
    };

    /**
     * Before this version when animations were disabled but keyframes already existed, the last keyframe of the animations would be visible. This version does not render the animations if they are disabled.
     * @returns If the animations should not be rendered if they are disabled.
     */
    static shouldNotRenderAnimationsIfDisabled = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_NOT_RENDER_ANIMATIONS_IF_DISABLED;
    };

    /**
     * In previous versions, when the video start time was greater then 0 (visibility bar), the video would still stop at the same time as when the video started at 0. This version fixes that.
     * @returns If the media layers should end later.
     */
    static shouldMediaLayersEndLater = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.MEDIA_LAYERS_SHOULD_END_LATER;
    };

    /**
     * When template designer first loads in, use the keyframes from the export for the first time. Then it doesn't need to be generated. In a new version because before this the calculation of keyframes was wrong. And then it showed the wrong animation.
     * @returns If the keyframes should be used from the export on mount.
     */
    static shouldUseExportKeyframesOnMount = (templateVersion?: TemplateSetup['templateVersion']): boolean => {
        if (!templateVersion) templateVersion = this.getTemplateVersion();
        return parseInt(templateVersion) >= this.SHOULD_USE_EXPORT_KEYFRAMES_ONMOUNT;
    };
}

export { TemplateVersionHelpers };
