import set from 'lodash/set';
import unset from 'lodash/unset';
import isEqual from 'lodash/isEqual';
import { EventEmitterTypes } from 'types/event-emitter.type';
import React from 'react';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { SceneHelpers } from 'helpers/scene.helpers';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import cloneDeep from 'helpers/cloneDeep';
import Icon from 'components/ui-components-v2/Icon';
import FrameType from '../types/frameTypes.type';
import Template, { RightSidebarTab, State, UnitOptions, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import FrameTypeHelpers from './frame-type.helpers';
import { CanvasHelpers } from './canvas.helpers';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import {
    DEFAULT_VISIBILITY,
    HIDE_TIMELINE_HEIGHT,
    MIN_ANIMATION_DURATION,
    MOST_ZOOMED_IN_SECONDS,
    MOST_ZOOMED_OUT_SECONDS,
    TOTAL_TIMELINE_MARGIN
} from '../constants';
import LayerProperties, { CustomAndPredefinedAnimations, PredefinedAnimations, Visibility } from '../types/layerProperties.type';
import Animation, {
    ActiveAnimation,
    ActiveAnimationType,
    AnimationOptions,
    AnimationValue,
    PredefinedAnimationOptions,
    PredefinedAnimationValue
} from '../types/animation.type';
import { generateKey } from '../utils/generateKey';
import { StylingHelpers } from './styling.helpers';
import getPredefinedAnimationsSetup, { PredefinedAnimationCategories, PredefinedAnimationSetup } from '../config/predefined-animations-setup';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import Layer from '../types/layer.type';
import { animationOptions } from '../config/animationOptions';
import { roundToNearestDecimal, roundToNearestNumber } from '../utils/roundNumbers';
import LayerHelpers from './layer.helpers';
import FormatHelpers from './format.helpers';
import { DynamicLayerHelpers } from './dynamic-layer.helpers';
import Format from '../types/format.type';

class TimelineHelpers {
    /**
     * Adds a new keyframe to the animations of a layer.
     * @param layer - Layer to add keyframe to.
     * @param attrKey - The attribute you want to animate (e.g. position or size)
     * @param time - The time to add the keyframe to. If not provided, the current time will be used.
     */
    static addKeyframe = (layerKey: Layer['key'], attrKey: AnimationOptions, time?: number): void => {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formats = getTemplateData<Template['formats']>('formats', { clone: false });
        const selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats', { clone: false });
        const currentFormat = formats.length === 1 ? 'general' : selectedFormats[0];

        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
        const currentTime = time !== undefined ? roundToNearestDecimal(time, 500) : SceneHelpers.getSceneTime(frameDuration);
        const stamp = this.secondsToStamp(currentTime, frameDuration);

        const changes: MultiModel = [];

        const layerPropertiesModel = `layerProperties.${currentFormat}.${frameType}.${layerKey}`;
        const newLayerProperties = getTemplateData<LayerProperties>(layerPropertiesModel);
        if (newLayerProperties && Array.isArray(newLayerProperties.animations)) {
            newLayerProperties.animations = {};
            changes.push([layerPropertiesModel, newLayerProperties]);
        }

        const layerClass = `.layers-container__layer.${layerKey}`;

        /**
         * Get the general value for the attribute key.
         * @param attrKey - The attribute key.
         * @param frameType - The frame type.
         * @param layer - The layer.
         * @param layerClass - The layer class.
         * @returns The general value for the attribute key.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function getGeneralValue(attrKey: AnimationOptions, frameType: FrameType['key'], layer: Layer, layerClass: string): any {
            let generalFormat: Format['key'] | null = null;

            /**
             * Check each format if it has general styling for the attribute key.
             */

            for (const format of formats) {
                const hasAnimationOverwrites = !!getTemplateData<LayerProperties>(
                    `layerProperties.${format.key}.${frameType}.${layer.key}.animations.${attrKey}`
                );

                const hasPropertyOverwrites = (() => {
                    switch (attrKey) {
                        case 'position': {
                            const x = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.x`, {
                                clone: false
                            });
                            const y = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.y`, {
                                clone: false
                            });
                            return x !== undefined && y !== undefined;
                        }
                        case 'size': {
                            const width = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.width`, {
                                clone: false
                            });
                            const height = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.height`, {
                                clone: false
                            });
                            return width !== undefined && height !== undefined;
                        }
                        case 'scale':
                        case 'rotation':
                        case 'opacity':
                        case 'color':
                        case 'backgroundColor':
                        case 'shadow':
                            return (
                                getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.${attrKey}`) !== undefined
                            );
                    }
                })();

                if (!hasAnimationOverwrites && !hasPropertyOverwrites) {
                    generalFormat = format.key;

                    // If it found a format, break the loop. We only need to know the first format.
                    break;
                }
            }

            // If there are formats with general styling, return the value of the first format.
            if (generalFormat !== null) {
                const layerElement = document.querySelector<HTMLElement>(layerClass + `.${generalFormat}`);
                if (layerElement) {
                    switch (attrKey) {
                        case 'position':
                            return StylingHelpers.getCurrentPosition(layerElement, layer.key, 'general');
                        case 'size':
                            return StylingHelpers.getCurrentSize(layerElement, 1);
                        case 'scale':
                            return {
                                value: StylingHelpers.getCurrentScale(layerElement),
                                transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                            };
                        case 'rotation':
                            return {
                                value: StylingHelpers.getCurrentRotation(layerElement),
                                transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                            };
                        case 'opacity':
                            return StylingHelpers.getCurrentOpacity(layerElement);
                        case 'color':
                        case 'backgroundColor':
                            return StylingHelpers.getCurrentColor(layerElement, attrKey);
                        case 'shadow':
                            return StylingHelpers.getCurrentShadow(layerElement);
                    }
                }
            }

            switch (attrKey) {
                case 'position': {
                    const templateData = getTemplateData<LayerProperties['properties']>(`layerProperties.general.${frameType}.${layerKey}.properties`, {
                        clone: false
                    });

                    if (templateData.x && templateData.y) {
                        return {
                            x: cloneDeep(templateData.x),
                            y: cloneDeep(templateData.y)
                        };
                    }

                    const defaultProperties = LayerPropertiesHelpers.getDefaultProperties(layer.type);

                    return {
                        x: defaultProperties?.x ?? { value: 0, unit: UnitOptions['Pixels'] },
                        y: defaultProperties?.y ?? { value: 0, unit: UnitOptions['Pixels'] }
                    };
                }
                case 'size': {
                    const templateData = getTemplateData<LayerProperties['properties']>(`layerProperties.general.${frameType}.${layerKey}.properties`, {
                        clone: false
                    });

                    if (templateData.width && templateData.height) {
                        return {
                            width: cloneDeep(templateData.width),
                            height: cloneDeep(templateData.height)
                        };
                    }

                    const defaultProperties = LayerPropertiesHelpers.getDefaultProperties(layer.type);

                    return {
                        width: defaultProperties?.width ?? { value: 0, unit: UnitOptions['Pixels'] },
                        height: defaultProperties?.height ?? { value: 0, unit: UnitOptions['Pixels'] }
                    };
                }
                default:
                    return (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        getTemplateData<any>(`layerProperties.general.${frameType}.${layerKey}.properties.${attrKey}`) ??
                        LayerPropertiesHelpers.getDefaultProperties(layer.type)?.[attrKey]
                    );
            }
        }

        /**
         * Add a keyframe to the animations of a layer.
         * @param formatKey - The format key to add the keyframe to.
         * @param layerKey - The layer key to add the keyframe to.
         * @param attrKey - The attribute key to add the keyframe to.
         * @param frameType - The frame type to add the keyframe to.
         * @param formatValue - The value of the keyframe.
         * @param stamp - The stamp of the keyframe.
         * @param changeActiveAnimation - Whether to change the active animation.
         */
        function addKeyframe(
            formatKey: Format['key'],
            layerKey: Layer['key'],
            attrKey: AnimationOptions,
            frameType: View['frameType'],
            formatValue: AnimationValue['value'],
            stamp: number,
            changeActiveAnimation?: boolean
        ) {
            const newKeyframe: AnimationValue = {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value: formatValue as any,
                stamp,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            };

            let keyframes = TimelineHelpers.getKeyframesFormat(formatKey, layerKey, attrKey, frameType);
            keyframes.push(newKeyframe);
            keyframes = TimelineHelpers.sortKeyframes(keyframes);
            keyframes = TimelineHelpers.checkSameTimestamp(keyframes);
            changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.animations.${attrKey}`, keyframes]);

            if (changeActiveAnimation) {
                const newActiveAnimations: State['activeAnimations'] = [
                    {
                        layer: layerKey,
                        type: ActiveAnimationType.Keyframe,
                        animationKey: attrKey,
                        keyframeKey: newKeyframe.id
                    }
                ];

                changes.push(['state.activeAnimations', newActiveAnimations]);
            }
        }

        /**
         * Get side effects for the keyframe.
         * @param attrKey - The attribute key.
         * @param formatKey - The format key.
         * @param frameType - The frame type.
         * @param layerKey - The layer key.
         */
        function getSideEffects(attrKey: keyof CustomAndPredefinedAnimations, formatKey: Format['key'], frameType: View['frameType'], layerKey: Layer['key']) {
            // Gather side effects based on the attribute key.
            switch (attrKey) {
                case 'position':
                    // Prevent that users can reposition the layer in the Creative Builder.
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggable`, false]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggableX`, false]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggableY`, false]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.horizontalAlign`, null]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.verticalAlign`, null]);
                    break;
                case 'size':
                    // Prevent that users can reposition the layer in the Creative Builder.
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggable`, false]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggableX`, false]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.canEdit.draggableY`, false]);
                    break;
                case 'scale':
                    // Prevent that users can reposition the layer in the Creative Builder.
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.horizontalAlign`, null]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.verticalAlign`, null]);
                    break;
                case 'rotation':
                    // Prevent that users can reposition the layer in the Creative Builder.
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.horizontalAlign`, null]);
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.verticalAlign`, null]);
                    break;
                case 'opacity':
                    changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.hover.properties.enableProperties.opacity`, false]);
                    break;
            }
        }

        // Check if the attribute is of a type that has no layerProperty value. e.g. rotationX or rotationY.
        if (attrKey === 'rotationX' || attrKey === 'rotationY') {
            const keyframes =
                TemplateDesignerStore.getModelWithFallback<Animation[]>([
                    `layerProperties.${currentFormat}.${frameType}.${layerKey}.animations.${attrKey}`,
                    `layerProperties.general.${frameType}.${layerKey}.animations.${attrKey}`
                ]) || [];

            const closestKeyframe = TimelineHelpers.findClosestKeyframeBeforeStamp(keyframes, stamp);

            if (closestKeyframe?.value) {
                addKeyframe(currentFormat, layerKey, attrKey, frameType, closestKeyframe.value, stamp, true);
            } else {
                const defaultValues: {
                    [key in AnimationOptions]?: AnimationValue['value'];
                } = {
                    rotationX: {
                        value: 0
                    },
                    rotationY: {
                        value: 0
                    }
                };

                if (defaultValues[attrKey]) {
                    addKeyframe(currentFormat, layerKey, attrKey, frameType, defaultValues[attrKey], stamp, true);
                }
            }
        } else if (currentFormat === 'general') {
            const generalValue = getGeneralValue(attrKey, frameType, layer, layerClass);
            addKeyframe(currentFormat, layerKey, attrKey, frameType, generalValue, stamp, true);
            getSideEffects(attrKey, currentFormat, frameType, layerKey);

            formats.forEach((format) => {
                const layerElement = document.querySelector<HTMLElement>(layerClass + `.${format.key}`);
                if (!layerElement) return;

                let formatValue;
                switch (attrKey) {
                    case 'position':
                        formatValue = StylingHelpers.getCurrentPosition(layerElement, layer.key, format.key);
                        break;
                    case 'size':
                        formatValue = StylingHelpers.getCurrentSize(layerElement, 1);
                        break;
                    case 'scale':
                        formatValue = {
                            value: StylingHelpers.getCurrentScale(layerElement),
                            transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                        };
                        break;
                    case 'rotation':
                        formatValue = {
                            value: StylingHelpers.getCurrentRotation(layerElement),
                            transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                        };
                        break;
                    case 'opacity':
                        formatValue = StylingHelpers.getCurrentOpacity(layerElement);
                        break;
                    case 'color':
                    case 'backgroundColor':
                        formatValue = StylingHelpers.getCurrentColor(layerElement, attrKey);
                        break;
                    case 'shadow':
                        formatValue = StylingHelpers.getCurrentShadow(layerElement);
                        break;
                }

                if (!isEqual(generalValue, formatValue)) {
                    addKeyframe(format.key, layerKey, attrKey, frameType, formatValue, stamp);
                    getSideEffects(attrKey, format.key, frameType, layerKey);
                }
            });
        } else {
            const layerElement = document.querySelector<HTMLElement>(layerClass + `.${currentFormat}`);
            if (!layerElement) return;

            let formatValue;
            switch (attrKey) {
                case 'position':
                    formatValue = StylingHelpers.getCurrentPosition(layerElement, layer.key, currentFormat);
                    break;
                case 'size':
                    formatValue = StylingHelpers.getCurrentSize(layerElement, 1);
                    break;
                case 'scale':
                    formatValue = {
                        value: StylingHelpers.getCurrentScale(layerElement),
                        transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                    };
                    break;
                case 'rotation':
                    formatValue = {
                        value: StylingHelpers.getCurrentRotation(layerElement),
                        transformOrigin: StylingHelpers.getCurrentTransformOrigin(layerElement)
                    };
                    break;
                case 'opacity':
                    formatValue = StylingHelpers.getCurrentOpacity(layerElement);
                    break;
                case 'color':
                case 'backgroundColor':
                    formatValue = StylingHelpers.getCurrentColor(layerElement, attrKey);
                    break;
                case 'shadow':
                    formatValue = StylingHelpers.getCurrentShadow(layerElement);
                    break;
            }

            addKeyframe(currentFormat, layerKey, attrKey, frameType, formatValue, stamp, true);
        }

        TemplateDesignerStore.save([
            ['state.selectedLayers', [layer]],
            ['view.showTab', 'animate'],
            ['state.selectedAnimation', { layer: layerKey, animation: attrKey }],
            ...changes
        ]);
    };

    private static findClosestKeyframeBeforeStamp = (keyframes: Animation[], stamp: Animation['stamp']): AnimationValue | undefined => {
        let newClosestKeyframe;

        // Loop through each keyframe
        for (const keyframe of keyframes) {
            // If the keyframe's stamp is less than or equal to the target
            if (keyframe.stamp <= stamp) {
                // If we haven't found a closest keyframe yet, or this one is closer
                if (!newClosestKeyframe || keyframe.stamp > newClosestKeyframe.stamp) {
                    newClosestKeyframe = keyframe;
                }
            }
        }

        return newClosestKeyframe;
    };

    /**
     * Adds a predefined animation to the layer.
     * @param layer - Layer to add the animation to.
     * @param category Category of the animation
     * @param animationKey Key of the animation
     * @param animationDuration Duration of the animation
     * @param time - Time to add the animation to. If not provided, the current time will be used.
     */
    static addPredefinedAnimation = (
        layer: Layer,
        category: PredefinedAnimationCategories,
        animationKey: PredefinedAnimationOptions,
        animationDuration: number,
        time?: number
    ): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const selectedFormat = getTemplateData<State['selectedFormats'][0]>('state.selectedFormats')[0];
        const generalProperties = getTemplateData<LayerProperties>(`layerProperties.general.${frameType}.${layer.key}`);
        const formatProperties = getTemplateData<LayerProperties>(`layerProperties.${selectedFormat}.${frameType}.${layer.key}`);
        const mergedLayerProperties = LayerPropertiesHelpers.mergeLayerProperties(
            layer.key,
            frameType,
            [selectedFormat],
            undefined,
            generalProperties,
            formatProperties
        );

        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
        const currentTime = time !== undefined ? time : SceneHelpers.getSceneTime(frameDuration);

        const durationInStamp = this.secondsToStamp(animationDuration, frameDuration);
        const beginTimeStamp = (() => {
            if (category === PredefinedAnimationCategories.TransitionIn) {
                if (mergedLayerProperties.layerProps.visibility?.[0] && mergedLayerProperties.layerProps.visibility?.[0] > 0) {
                    return mergedLayerProperties.layerProps.visibility?.[0];
                }
                return 0;
            } else if (category === PredefinedAnimationCategories.TransitionOut) {
                if (
                    mergedLayerProperties.layerProps.visibility?.[1] &&
                    mergedLayerProperties.layerProps.visibility?.[1] < 1 &&
                    mergedLayerProperties.layerProps.visibility?.[1] + durationInStamp < 1
                ) {
                    return mergedLayerProperties.layerProps.visibility?.[1] - durationInStamp;
                }
                return 1 - durationInStamp;
            }

            return this.secondsToStamp(currentTime, frameDuration);
        })();
        const endTimeStamp = beginTimeStamp + durationInStamp;

        const currentPredefinedAnimationSetup = getPredefinedAnimationsSetup();
        const defaultValue: PredefinedAnimationSetup['defaultValue'] = currentPredefinedAnimationSetup[category].animations[animationKey].defaultValue;
        const newIndex = this.getNewPredefinedAnimationIndex(mergedLayerProperties.layerProps, animationKey);

        const firstKeyframeId = generateKey();
        const secondKeyframeId = generateKey();
        const uniqueAttributeKey = generateKey(animationKey) as keyof CustomAndPredefinedAnimations;

        const newAnimationKeyframes: [PredefinedAnimationValue, PredefinedAnimationValue] = [
            {
                id: firstKeyframeId,
                stamp: beginTimeStamp,
                index: newIndex,
                type: animationKey,
                ...defaultValue
            },
            {
                id: secondKeyframeId,
                stamp: endTimeStamp,
                index: newIndex,
                type: animationKey,
                ...defaultValue
            }
        ];

        const formatToUpdate = FormatHelpers.currentFormat();

        const newActiveAnimations: State['activeAnimations'] = [
            {
                layer: layer.key,
                type: ActiveAnimationType.PredefinedAnimation,
                animationKey: uniqueAttributeKey,
                keyframeKey: firstKeyframeId
            }
        ];

        TemplateDesignerStore.save([
            [`layerProperties.${formatToUpdate}.${frameType}.${layer.key}.animations.${uniqueAttributeKey}`, newAnimationKeyframes],
            ['view.showTab', RightSidebarTab.Animate],
            ['state.activeAnimations', newActiveAnimations],
            ['state.selectedLayers', [layer]]
        ]);
    };

    /**
     * Get the keyframes for the format, layer and attribute.
     * @param formatKey - The format key.
     * @param layerKey - The layer key.
     * @param attrKey - The attribute key.
     * @param frameType - The frame type. Defaults to the current frame type.
     * @returns The keyframes for the format, layer and attribute.
     */
    static getKeyframesFormat = (
        formatKey: Format['key'],
        layerKey: Layer['key'],
        attrKey: keyof CustomAndPredefinedAnimations,
        frameType?: View['frameType']
    ): Animation[] => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const formatKeyframes = getTemplateData<AnimationValue[] | null>(`layerProperties.${formatKey}.${frameType}.${layerKey}.animations.${attrKey}`);
        if (formatKeyframes === null) return [];
        if (formatKeyframes) return formatKeyframes;

        const generalKeyframes = getTemplateData<AnimationValue[] | undefined>(`layerProperties.general.${frameType}.${layerKey}.animations.${attrKey}`);
        if (!generalKeyframes) return [];
        return generalKeyframes;
    };

    /**
     * Filters the animation properties of a layer.
     * @param unfilteredAnimations - All animation properties of a layer.
     * @param type - The animation type e.g. predefinedAnimations or animations
     * @returns One of the two animation types.
     */
    static filerAnimations = (
        unfilteredAnimations: LayerProperties['animations'],
        type: 'predefinedAnimations' | 'animations'
    ): CustomAndPredefinedAnimations => {
        if (unfilteredAnimations === undefined) unfilteredAnimations = {};

        const animations = {};
        const customAnimationOptions = animationOptions.map((animationOption) => animationOption.key as string);

        /**
         * Add all animation options to the unfilteredAnimations object.
         */
        animationOptions.forEach((option) => {
            if (unfilteredAnimations === undefined) unfilteredAnimations = {};
            if (unfilteredAnimations[option.key] === undefined) {
                unfilteredAnimations[option.key] = [];
            }
        });

        Object.keys(unfilteredAnimations).forEach((animation) => {
            const animationFilter = customAnimationOptions.includes(animation);

            if (type === 'predefinedAnimations' && !animationFilter) {
                if (unfilteredAnimations?.[animation]) {
                    animations[animation] = cloneDeep(unfilteredAnimations?.[animation]);
                }
            } else if (type === 'animations' && animationFilter) {
                if (unfilteredAnimations?.[animation]) {
                    animations[animation] = cloneDeep(unfilteredAnimations?.[animation]);
                }
            }
        });

        return animations;
    };

    /**
     * Get the index for the new predefined animation.
     * @param layerProperties - The layer properties.
     * @param animationKey - The animation key.
     * @returns The index for the new predefined animation.
     */
    static getNewPredefinedAnimationIndex = (layerProperties: LayerProperties, animationKey: PredefinedAnimationOptions): number => {
        if (!layerProperties) return 1;

        const predefinedAnimations = this.filerAnimations(layerProperties.animations, 'predefinedAnimations');
        if (!predefinedAnimations || !Object.keys(predefinedAnimations).length) return 1;

        const animationsOfType = Object.keys(predefinedAnimations).filter(
            (predefinedAnimationKey) => predefinedAnimations[predefinedAnimationKey]?.[0]?.type === animationKey
        );

        return (
            animationsOfType.reduce((acc, predefinedAnimation) => {
                const animationIndex = predefinedAnimations[predefinedAnimation][0].index;
                if (animationIndex < acc) {
                    return acc;
                }

                return animationIndex;
            }, 0) + 1
        );
    };

    /**
     * Delete the active animations from the store.
     * @param activeAnimations - The animations to be deleted.
     */
    static deleteAnimations = (activeAnimations?: State['activeAnimations']): void => {
        if (activeAnimations === undefined) activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');
        if (!activeAnimations || activeAnimations.length === 0) return;

        // If only one visibility is active, delete the layer.
        if (activeAnimations.length === 1 && activeAnimations[0].type === ActiveAnimationType.Visibility) {
            return LayerHelpers.deleteLayer(activeAnimations[0].layer);
        }

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formatToUpdate = FormatHelpers.currentFormat();

        const changes: MultiModel = [
            ['state.activeAnimations', []],
            ['showTab', RightSidebarTab.LayerEdit, false]
        ];

        const activeAnimationsGroupedByLayer = activeAnimations.reduce(
            (all, animation) => {
                if (!all[animation.layer]) {
                    all[animation.layer] = [];
                }

                all[animation.layer].push(animation);
                return all;
            },
            {} as Record<Layer['key'], State['activeAnimations']>
        );

        // Delete the active keyframes and get new keyframes for each layer.
        const newKeyframes = Object.keys(activeAnimationsGroupedByLayer).reduce(
            (allKeyframes, layer) => {
                const formatKeyframes =
                    getTemplateData<LayerProperties['animations']>(`layerProperties.${formatToUpdate}.${frameType}.${layer}.animations`) || {};

                const generalKeyframes = getTemplateData<LayerProperties['animations']>(`layerProperties.general.${frameType}.${layer}.animations`);

                const animations = activeAnimationsGroupedByLayer[layer];

                animations.forEach((animation) => {
                    if (!animation.animationKey) return;

                    if (animation.type === ActiveAnimationType.PredefinedAnimation) {
                        if (formatToUpdate === 'general') {
                            return unset(formatKeyframes, animation.animationKey);
                        }

                        if (Array.isArray(generalKeyframes) || generalKeyframes?.[animation.animationKey]?.length === 0) {
                            return unset(formatKeyframes, animation.animationKey);
                        }

                        return set(formatKeyframes, animation.animationKey, null);
                    }

                    const newKeyframes = (formatKeyframes?.[animation.animationKey] || generalKeyframes?.[animation.animationKey])?.filter(
                        (keyframe) => keyframe.id !== animation.keyframeKey
                    );

                    if (!newKeyframes) return;

                    // If the format is not general and there are no keyframes left, set the animation to null. So we know to not apply the animation on that specific format.
                    if (formatToUpdate !== 'general' && newKeyframes.length === 0) {
                        const newConflictingAnimations = this.getNewConflictingAnimations(frameType, animation.layer, animation.animationKey);
                        set(formatKeyframes, animation.animationKey, null);
                        return changes.push(['state.conflictingAnimations', newConflictingAnimations]);
                    } else if (formatToUpdate === 'general' && newKeyframes.length === 0) {
                        return unset(formatKeyframes, animation.animationKey);
                    } else {
                        return set(formatKeyframes, animation.animationKey, newKeyframes);
                    }
                });

                allKeyframes[layer] = formatKeyframes;
                return allKeyframes;
            },
            {} as [layerKey: Layer['key'], CustomAndPredefinedAnimations]
        );

        // Check if the next keyframe should be selected. It should only be selected when there is only 1 custom keyframe animation selected. 1 or more keyframes.
        const shouldSelectTheNextKeyframe = (() => {
            const firstAnimation = activeAnimations[0];

            const { animationKey: firstAnimationKey, layer: firstLayer } = firstAnimation;

            // Check if all active animations are the same.
            for (const animation of activeAnimations) {
                if (animation.animationKey !== firstAnimationKey || animation.layer !== firstLayer) {
                    return false;
                }
            }

            return true;
        })();

        if (shouldSelectTheNextKeyframe) {
            const layer = activeAnimations[0].layer;
            const animation = activeAnimations[0].animationKey;

            if (layer && animation && newKeyframes?.[layer]?.[animation]?.length > 0) {
                changes.push([
                    'state.activeAnimations',
                    [
                        {
                            type: ActiveAnimationType.Keyframe,
                            keyframeKey: newKeyframes[layer][animation][0].id,
                            animationKey: activeAnimations[0].animationKey,
                            layer: activeAnimations[0].layer
                        }
                    ]
                ]);

                // Seek to the new active keyframe.
                const time = TimelineHelpers.stampToSeconds(newKeyframes[layer][animation][0].stamp);
                TimelineHelpers.seekTo(time);
            }
        }

        // Generate layer property changes for the new keyframes.
        Object.keys(newKeyframes).forEach((layer) => {
            changes.push([`layerProperties.${formatToUpdate}.${frameType}.${layer}.animations`, newKeyframes[layer]]);
        });

        TemplateDesignerStore.save(changes);
    };

    /**
     * Get new conflicting animation from the state.
     * @param layerKey - Key of the layer
     * @param animationKey - Key of the animation attribute if not provided it will remove the complete layer from the conflicting animations
     */
    static getNewConflictingAnimations = (
        frameType: View['frameType'],
        layerKey?: Layer['key'],
        propertyKey?: keyof CustomAndPredefinedAnimations
    ): State['conflictingAnimations'] => {
        const conflictingAnimations = getTemplateData<State['conflictingAnimations']>(`state.conflictingAnimations`);

        if (!layerKey) {
            unset(conflictingAnimations, frameType);
        } else if (!propertyKey) {
            unset(conflictingAnimations?.[frameType], layerKey);
            if (conflictingAnimations?.[frameType]?.length === 0) unset(conflictingAnimations, frameType);
        } else {
            const animationKey = (() => {
                switch (propertyKey as string) {
                    case 'shadowColor': {
                        return 'shadow';
                    }
                    default: {
                        return propertyKey;
                    }
                }
            })();

            if (!conflictingAnimations?.[frameType]?.[layerKey]?.includes(animationKey)) return;

            conflictingAnimations[frameType][layerKey] = conflictingAnimations[frameType][layerKey].filter((attr) => attr !== animationKey);
            if (conflictingAnimations[frameType][layerKey]?.length === 0) unset(conflictingAnimations[frameType], layerKey);
            if (conflictingAnimations[frameType]?.length === 0) unset(conflictingAnimations, frameType);
        }

        return conflictingAnimations;
    };

    /**
     * Get the current timestamp.
     * @param frameType - The frame type to use.
     * @returns The current timestamp.
     */
    static getCurrentStamp = (frameType?: View['frameType'], currentTime?: number): number => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);
        if (currentTime === undefined) currentTime = SceneHelpers.getSceneTime(frameDuration);
        const currentTimestamp = currentTime / frameDuration;
        return currentTimestamp;
    };

    /**
     * Convert a timestamp to seconds.
     * @param stamp - The timestamp to convert.
     * @param frameDuration - The frame duration to use. Defaults to the current frame duration.
     * @returns The timestamp in seconds.
     */
    static stampToSeconds = (stamp: number, frameDuration?: FrameType['duration'], round = false): number => {
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        if (!round) {
            return frameDuration * stamp;
        } else {
            return roundToNearestDecimal(frameDuration * stamp, 500);
        }
    };

    /**
     * Convert seconds to a timestamp.
     * @param seconds - The seconds to convert.
     * @param frameDuration - The frame duration to use. Defaults to the current frame duration.
     * @returns The seconds in a timestamp.
     */
    static secondsToStamp = (seconds: number, frameDuration?: FrameType['duration']): number => {
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        return roundToNearestDecimal(seconds / frameDuration, 500);
    };

    /**
     * Convert pixels to a timestamp.
     * @param pixels - The pixels to convert.
     * @param timelineWidth - The timeline width.
     * @returns The pixels in a timestamp.
     */
    static pixelsToStamp = (pixels: number, timelineWidth: number): number => {
        return roundToNearestDecimal(pixels / timelineWidth, 500);
    };

    /**
     * Convert a pixels to seconds.
     * @param pixels Pixels to get the current second of
     * @param timelineWidth Width of the timeline
     * @param frameDuration Duration of the frame
     * @returns Current second of the pixels
     */
    static pixelsToSeconds = (pixels: number, timelineWidth: number, frameDuration?: FrameType['duration']): number => {
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        const stamp = pixels / timelineWidth;
        const milliseconds = this.stampToSeconds(stamp, frameDuration, false) * 1000;
        const roundedMilliseconds = roundToNearestNumber(milliseconds);
        const newCurrentTime = roundedMilliseconds / 1000;

        return newCurrentTime;
    };

    /**
     * Convert milliseconds to timeline percentage
     * @param time - milliseconds
     * @returns Timeline percentage between 0 and 1
     */
    static secondsToTimelinePercentage = (time: number, frameDuration?: FrameType['duration']): number => {
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        return roundToNearestDecimal(time / frameDuration, 1000);
    };

    /**
     * Convert timeline percentage to milliseconds
     * @param percentage timeline percentage between 0 and 1
     * @returns milliseconds based on the percentage and duration
     */
    static timelinePercentageToSeconds = (percentage: number): number => {
        const frameDuration = FrameTypeHelpers.getFrameDuration();
        return roundToNearestDecimal(percentage * frameDuration, 100);
    };

    /**
     * Change the timing of the visibility bar based on the distance moved and cap it at 0 and 1
     * @param stamps Stamps of the visibility bar
     * @param distance Distance to move the visibility bar
     * @returns New start and end stamps of the visibility bar capped at 0 and 1
     */
    static changeTimingOfVisibility = (stamps: number[], distance: number): Visibility => {
        const newStart = roundToNearestDecimal(stamps[0] + distance, 500);
        const newEnd = roundToNearestDecimal(stamps[1] + distance, 500);

        return [newStart, newEnd];
    };

    /**
     * Change the timing of the keyframes in the animations
     * @param distance The distance to move the keyframes
     * @param animations The animations to move
     * @returns The new animations with the keyframes moved
     */
    static changeTimingOfKeyframes = (distance: number, animations: CustomAndPredefinedAnimations): CustomAndPredefinedAnimations => {
        const newAnimations: CustomAndPredefinedAnimations = cloneDeep(animations);
        Object.values(newAnimations).map((animation) => {
            if (animation && animation.length) {
                animation.forEach((keyframe) => {
                    keyframe.stamp += distance;
                });
            }
        });

        return newAnimations;
    };

    /**
     * Get the icon for the animation type.
     * @param type - The animation type.
     * @returns The icon.
     */
    static getAnimationIcon = (type: keyof CustomAndPredefinedAnimations, className?: string): React.ReactNode => {
        const position = (
            <Icon className={className} color="inherit">
                open_with
            </Icon>
        );
        const size = (
            <Icon className={className} color="inherit">
                height
            </Icon>
        );
        const scale = (
            <Icon className={className} color="inherit">
                fullscreen
            </Icon>
        );
        const rotation = (
            <Icon className={className} color="inherit">
                rotate_right
            </Icon>
        );
        const rotationX = (
            <Icon className={className} color="inherit" style={{ transform: 'rotate(90deg)' }}>
                360
            </Icon>
        );
        const rotationY = (
            <Icon className={className} color="inherit">
                360
            </Icon>
        );
        const opacity = (
            <Icon className={className} color="inherit">
                opacity
            </Icon>
        );
        const backgroundColor = (
            <Icon className={className} color="inherit">
                format_color_fill
            </Icon>
        );
        const color = (
            <Icon className={className} color="inherit">
                palette
            </Icon>
        );
        const shadow = (
            <Icon className={className} color="inherit">
                gradient
            </Icon>
        );
        const defaultIcon = (
            <Icon className={className} color="inherit">
                offline_bolt
            </Icon>
        );

        return (
            {
                position,
                size,
                scale,
                rotation,
                rotationX,
                rotationY,
                opacity,
                backgroundColor,
                color,
                shadow
            }[type] ?? defaultIcon
        );
    };

    /**
     * Get the title of the animation.
     * @param animation - The animation itself.
     * @param animationKey - The animation key.
     * @returns A string representing the animation title.
     */
    static getAnimationTitle = (animation: Animation[], animationKey?: keyof CustomAndPredefinedAnimations, layer?: Layer): string => {
        const animationHasKeyframes = animation && animation.length > 0;
        let type = animationHasKeyframes && 'type' in animation[0] ? animation[0].type : animationKey;
        let index = animationHasKeyframes && 'index' in animation[0] ? animation[0].index : '';

        if (!animationHasKeyframes) {
            if (animationKey) {
                const isCustomAnimation = TimelineHelpers.isCustomAnimation(animationKey);

                if (isCustomAnimation) {
                    return Translation.get(`timeline.animationOptions.${animationKey}`, 'template-designer');
                }
            }

            if (layer) {
                const frameType = getTemplateData<View['frameType']>('view.frameType');
                const generalAnimation = getTemplateData<PredefinedAnimationValue[]>(
                    `layerProperties.general.${frameType}.${layer.key}.animations.${animationKey}`
                );

                if (generalAnimation && generalAnimation.length > 0) {
                    type = generalAnimation[0].type;
                    index = generalAnimation[0].index;
                } else {
                    const formats = getTemplateData<Template['formats']>('formats', { clone: false });

                    let animation: CustomAndPredefinedAnimations[] | undefined;
                    for (const format of formats) {
                        const formatAnimations = getTemplateData<CustomAndPredefinedAnimations[]>(
                            `layerProperties.${format.key}.${frameType}.${layer.key}.animations.${animationKey}`
                        );
                        if (formatAnimations && formatAnimations.length > 0) {
                            animation = formatAnimations;
                            break;
                        }
                    }

                    if (animation) {
                        type = 'type' in animation[0] && animation[0].type;
                        index = 'index' in animation[0] && animation[0].index;
                    }
                }
            }
        }

        const title = Translation.get(`timeline.animationOptions.${type}`, 'template-designer');
        if (index !== '') return title + ' ' + index;
        return title;
    };

    /**
     * Toggle the play state of the and the timeline.
     * @param shouldPlay - Should the timeline play or pause.
     * @param isPlaying - The current play state.
     * @param frameDuration - The duration of the frame.
     */
    static togglePlay = (shouldPlay?: boolean, isPlaying?: State['isPlaying'], frameDuration?: FrameType['duration']): void => {
        const formats = getTemplateData<Template['formats']>('formats', { clone: false });
        if (formats.length === 0) return SnackbarUtils.warning(Translation.get('canvas.empty.titleFormats', 'template-designer'));

        if (isPlaying === undefined) isPlaying = getTemplateData<State['isPlaying']>('state.isPlaying');
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        const currentTime = SceneHelpers.getSceneTime(frameDuration);
        const playing = shouldPlay !== undefined ? shouldPlay : !isPlaying;

        if (playing) {
            // load all media elements before playing the ads to make sure that the media elements are playable
            Promise.all(CanvasHelpers.loadMedia()).then(() => {
                SceneHelpers.playScene();
                TemplateDesignerStore.save(['state.isPlaying', true], { saveToHistory: false });
                CanvasHelpers.manageMediaLayers(true, currentTime, frameDuration);
                CanvasHelpers.manageLottieLayers(true, currentTime, frameDuration);
            });
        } else {
            SceneHelpers.pauseScene();
            TemplateDesignerStore.save(['state.isPlaying', false], { saveToHistory: false });
            CanvasHelpers.manageMediaLayers(false, currentTime, frameDuration);
            CanvasHelpers.manageLottieLayers(false, currentTime, frameDuration);
        }
    };

    /**
     * Seek the timeline, media and Lottie layers to the given time.
     * @param time - The time to seek to.
     * @param frameDuration - The duration of the frame.
     * @param play - Should the timeline play after seeking.
     */
    static seekTo = (time: number, frameDuration?: FrameType['duration'], play?: boolean): void => {
        if (!frameDuration) frameDuration = FrameTypeHelpers.getFrameDuration();
        if (time < 0) time = 0;
        if (time > frameDuration) time = frameDuration;
        EventEmitterHelpers.sent(EventEmitterTypes.TDcurrentTime, time);
        SceneHelpers.pauseScene();
        SceneHelpers.setSceneTime(time);
        CanvasHelpers.manageMediaLayers(false, time, frameDuration);
        CanvasHelpers.manageLottieLayers(false, time, frameDuration);
        if (typeof play === 'boolean') TimelineHelpers.togglePlay(play, undefined, frameDuration);
    };

    /**
     * Get the timeline size based on the scroll area width and the zoom level
     * @param scrollAreaWidth Width of the scroll area
     * @param zoom Current zoom of the timeline
     * @returns Second in pixels and the timeline width
     */
    static getTimelineSize = (
        scrollAreaWidth = 0,
        zoom: number,
        duration: number
    ): { secondInPx: number; timelineWidth: number; timelineWidthWithMargin: number } => {
        const newSecondInPx =
            duration < MOST_ZOOMED_IN_SECONDS ? (scrollAreaWidth - TOTAL_TIMELINE_MARGIN) / duration : (scrollAreaWidth - TOTAL_TIMELINE_MARGIN) * (zoom / 100);
        const newTimelineWidth = duration < MOST_ZOOMED_IN_SECONDS ? scrollAreaWidth - TOTAL_TIMELINE_MARGIN || 0 : duration * newSecondInPx;

        return {
            secondInPx: newSecondInPx,
            timelineWidth: newTimelineWidth,
            timelineWidthWithMargin: newTimelineWidth + TOTAL_TIMELINE_MARGIN
        };
    };

    /**
     * Get the minimum zoom level of the timline
     * @returns Minimum zoom level
     */
    static getMinZoom = (): number => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const duration = FrameTypeHelpers.getFrameDuration(frameType);
        return duration < MOST_ZOOMED_OUT_SECONDS ? 100 / duration : 100 / MOST_ZOOMED_OUT_SECONDS;
    };

    /**
     * Get the maximum zoom level of the timline
     * @returns Maximum zoom level
     */
    static getMaxZoom = (): number => {
        return 100 / MOST_ZOOMED_IN_SECONDS;
    };

    /**
     * Get the timeline data for the selected format and frame type
     * @param selectedFormat - The selected format
     * @param frameType - The frame type
     * @returns The timeline data
     */
    static getTimelineOverwrites = (selectedFormat: State['selectedFormats'][0], frameType: View['frameType']): { [layerKey: Layer['key']]: string[] } => {
        const propertiesFormat =
            selectedFormat !== 'general'
                ? getTemplateData<Template['layerProperties']>(`layerProperties.${selectedFormat}.${frameType}`, { clone: false })
                : {};
        const propertiesGeneral = getTemplateData<Template['layerProperties']>(`layerProperties.general.${frameType}`, { clone: false });

        const timelineOverwrites = {};

        Object.keys(propertiesGeneral).forEach((layerKey) => {
            for (const animationKey in propertiesGeneral[layerKey].animations) {
                const formatAnimation = propertiesFormat?.[layerKey]?.animations?.[animationKey];

                if (formatAnimation || formatAnimation === null) {
                    if (!timelineOverwrites[layerKey]) {
                        timelineOverwrites[layerKey] = [];
                    }

                    if (timelineOverwrites[layerKey].includes(animationKey as AnimationOptions)) continue;

                    timelineOverwrites[layerKey].push(animationKey as AnimationOptions);
                }
            }

            if (propertiesFormat?.[layerKey]?.animations && selectedFormat !== 'general') {
                for (const animationKey in propertiesFormat[layerKey].animations) {
                    const formatAnimation = propertiesFormat[layerKey]?.animations?.[animationKey];

                    if (formatAnimation || formatAnimation === null) {
                        if (!timelineOverwrites[layerKey]) {
                            timelineOverwrites[layerKey] = [];
                        }

                        if (timelineOverwrites[layerKey].includes(animationKey as AnimationOptions)) continue;

                        timelineOverwrites[layerKey].push(animationKey as AnimationOptions);
                    }
                }
            }
        });

        return timelineOverwrites;
    };

    /**
     * Get the minimum visibility duration in stamp.
     * @param frameType - Frame type to the duration for.
     * @returns Min visibility duration in stamp
     */
    static getMinVisibilityDurationInStamp = (frameType?: View['frameType']): number => {
        return MIN_ANIMATION_DURATION / FrameTypeHelpers.getFrameDuration(frameType);
    };

    /**
     * Get the default visibility
     * @returns The default visibility
     */
    static getDefaultVisibility = (): Visibility => {
        return cloneDeep(DEFAULT_VISIBILITY);
    };

    /**
     * Get the visibility of a layer.
     * @param layerKey - The key of the layer.
     * @param frameType - The frame type.
     * @param selectedFormat - The selected format.
     * @returns The visibility of the layer.
     */
    static getLayerVisibility = (layerKey: Layer['key'], frameType?: View['frameType'], selectedFormat?: State['selectedFormats'][0]): number[] => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        if (selectedFormat === undefined) selectedFormat = getTemplateData<State['selectedFormats'][0]>('state.selectedFormats')[0];

        const visibility =
            getTemplateData<LayerProperties['visibility']>(`layerProperties.${selectedFormat}.${frameType}.${layerKey}.visibility`) ||
            getTemplateData<LayerProperties['visibility']>(`layerProperties.general.${frameType}.${layerKey}.visibility`) ||
            TimelineHelpers.getDefaultVisibility();

        return visibility;
    };

    /**
     * Order layers based on parend child relation.
     * @param layers Layers to order
     * @returns Ordered layer keys
     */
    static getLayerOrder = (layers: Layer[]): Layer[] => {
        const result: Layer[] = [];

        const traverse = (layerArray: Layer[]) => {
            for (const layer of layerArray) {
                const newLayer = { ...layer };
                delete newLayer.children;

                result.push(newLayer);
                if (layer.children) {
                    traverse(layer.children);
                }
            }
        };

        traverse(layers);
        return result;
    };

    /**
     * Type guard to check if a value is a custom animation.
     * @param animation - Animation key.
     * @returns If an animation is a custom animation.
     */
    static isCustomAnimation = (animation: keyof CustomAndPredefinedAnimations): boolean => {
        return !!animationOptions.find((option) => option.key === animation);
    };

    /**
     * Type guard to check if a value is a custom animation.
     * @param animation - Animation key.
     * @returns If an animation is a custom animation.
     */
    static isPredefinedAnimation = (animation: keyof CustomAndPredefinedAnimations): boolean => {
        return !animationOptions.find((option) => option.key === animation);
    };

    /**
     * Check if the type is if the given predefined animation category
     * @param animation The type of the animation
     * @param category The category to check for
     * @returns If the type is if the given predefined animation category
     */
    static isOfPredefinedAnimationCategory = (animation: keyof CustomAndPredefinedAnimations, category: PredefinedAnimationCategories): boolean => {
        const predefinedAnimationCategoryKeys = Object.keys(getPredefinedAnimationsSetup()[category].animations);

        return predefinedAnimationCategoryKeys.includes(animation);
    };

    /**
     * Get the keyframes belonging to the active animation
     * @param activeAnimation The active animation
     * @returns The keyframes belonging to the active animation
     */
    static getKeyframesByActiveAnimation = (activeAnimation: ActiveAnimation): Animation[] | null => {
        if (!activeAnimation || !activeAnimation.layer) return null;
        const selectedFormat = getTemplateData<State['selectedFormats'][0]>('state.selectedFormats')[0];
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const keyframes = getTemplateData<Animation[] | undefined>(
            `layerProperties.${selectedFormat}.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`
        );

        if (keyframes) return keyframes;

        const generalKeyframes = getTemplateData<Animation[]>(
            `layerProperties.general.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`
        );

        return generalKeyframes;
    };

    /**
     * Get the keyframes belonging to the active animation
     * @param activeAnimation The active animation
     * @returns The keyframes belonging to the active animation
     */
    static getKeyframeByActiveAnimation = (activeAnimation: ActiveAnimation): Animation | undefined => {
        if (!activeAnimation || !activeAnimation.layer) return;
        const selectedFormat = getTemplateData<State['selectedFormats'][0]>('state.selectedFormats')[0];
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const formatSpecificKeyframe = getTemplateData<Animation[] | undefined>(
            `layerProperties.${selectedFormat}.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`
        )?.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);

        if (formatSpecificKeyframe) return formatSpecificKeyframe;

        const generalKeyframe = getTemplateData<Animation[] | undefined>(
            `layerProperties.general.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`
        )?.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);

        return generalKeyframe;
    };

    /**
     * Get keyframes based on current time.
     * @param keyframes - All keyframes.
     * @param frameDuration - Duration of the frame.
     * @param currentTime - Current time of the scene.
     * @returns Keyframe based on current time.
     */
    static getKeyframeByCurrentTime = (keyframes: Animation[], frameDuration?: FrameType['duration'], currentTime?: number): Animation => {
        if (frameDuration === undefined) frameDuration = FrameTypeHelpers.getFrameDuration();
        if (currentTime === undefined) currentTime = SceneHelpers.getSceneTime(frameDuration);

        const currentStamp = TimelineHelpers.secondsToStamp(currentTime, frameDuration);
        const WITHIN_RANGE = 0.005;
        const i = keyframes.findIndex((k) => Math.abs(k.stamp - currentStamp) <= WITHIN_RANGE);
        return keyframes[i];
    };

    /**
     * Get the visibility belonging to the active animation
     * @param activeAnimation The active animation
     * @returns The visibility belonging to the active animation
     */
    static getVisibilityByActiveAnimation = (activeAnimation: ActiveAnimation): Visibility | undefined => {
        if (!activeAnimation || !activeAnimation.layer) return;
        const selectedFormat = getTemplateData<State['selectedFormats'][0]>('state.selectedFormats')[0];
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const visibility = TemplateDesignerStore.getModelWithFallback<Visibility>([
            `layerProperties.${selectedFormat}.${frameType}.${activeAnimation.layer}.visibility`,
            `layerProperties.general.${frameType}.${activeAnimation.layer}.visibility`
        ]);

        if (visibility) return visibility;
        return TimelineHelpers.getDefaultVisibility();
    };

    /**
     * Get the stamp belonging to the active animation
     * @param activeAnimation The active animation
     * @param lastVisibility If the last visibility should be returned
     * @returns The stamp belonging to the active animation
     */
    static getStampFromActiveAnimation = (activeAnimation: ActiveAnimation, lastVisibility = false): number | undefined => {
        if (!activeAnimation || !activeAnimation.layer) return;

        if (activeAnimation.type === ActiveAnimationType.PredefinedAnimation || activeAnimation.type === ActiveAnimationType.Keyframe) {
            return TimelineHelpers.getKeyframeByActiveAnimation(activeAnimation)?.stamp;
        } else {
            return TimelineHelpers.getVisibilityByActiveAnimation(activeAnimation)?.[lastVisibility ? 1 : 0];
        }
    };

    /**
     * Activate the visibility bar for a layer
     * @param layer The layer of the visibility bar
     */
    static activateVisibilityBar = (layer: Layer): void => {
        const newActiveAnimations: State['activeAnimations'] = [
            {
                type: ActiveAnimationType.Visibility,
                layer: layer.key
            }
        ];
        TemplateDesignerStore.save(
            [
                ['state.activeAnimations', newActiveAnimations],
                ['view.showTab', RightSidebarTab.Animate],
                ['state.selectedLayers', [layer]]
            ],
            {
                saveToHistory: false
            }
        );
    };

    /**
     * Activate the predefined animation
     * @param layer The layer of the predefined animation
     * @param animationKey The animation key of the predefined animation
     * @param keyframeKey The keyframe key of the first predefined animation keyframe
     */
    static activatePredefinedAnimation = (layer: Layer, animationKey: keyof PredefinedAnimations, keyframeKey: Animation['id']): void => {
        const newActiveAnimations: State['activeAnimations'] = [
            {
                type: ActiveAnimationType.PredefinedAnimation,
                layer: layer.key,
                animationKey: animationKey,
                keyframeKey: keyframeKey
            }
        ];
        TemplateDesignerStore.save(
            [
                ['state.activeAnimations', newActiveAnimations],
                ['view.showTab', RightSidebarTab.Animate],
                ['state.selectedLayers', [layer]]
            ],
            {
                saveToHistory: false
            }
        );

        /**
         * Seek to the end time of the animation.
         * Get the animation keyframes.
         * Get the last keyframe.
         * Get the stamp of the last keyframe.
         */
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentFormat = FormatHelpers.currentFormat();
        const animation = TemplateDesignerStore.getModelWithFallback<PredefinedAnimationValue[]>([
            `layerProperties.${currentFormat}.${frameType}.${layer.key}.animations.${animationKey}`,
            `layerProperties.general.${frameType}.${layer.key}.animations.${animationKey}`
        ]);

        const stamp = animation[animation.length - 1].stamp;
        const time = TimelineHelpers.stampToSeconds(stamp);
        TimelineHelpers.seekTo(time);
    };

    /**
     * Move the timestamps after the visibility slider has been changed.
     * @param timestamps - Timestamps from the store.
     * @param startPosition - Visibility slider start position.
     * @param newPosition - Visibility slider end position.
     * @param duration - Duration of the frame.
     * @returns Modified timestamps with new position.
     */
    static moveTimestamps = (
        timestamps: CustomAndPredefinedAnimations,
        startPosition: number[],
        newPosition: number[],
        duration: number
    ): CustomAndPredefinedAnimations => {
        const newTimestamps = cloneDeep(timestamps);
        Object.keys(newTimestamps).forEach((attrKey) => {
            if (!newTimestamps[attrKey]) return;

            newTimestamps[attrKey].forEach((timestamp) => {
                timestamp.stamp = ((timestamp.stamp + (newPosition[0] - startPosition[0])) * duration * 10) / (duration * 10);
            });
        });

        return newTimestamps;
    };

    /**
     * Set the new position and keyframes for drag layer and child layers.
     * @param dragLayer - Current drag layer.
     * @param newPosition - New position from the slider.
     * @param oldPosition - Old position from the slider.
     */
    static handleVisibility = (dragLayer: Layer, newParentPosition: number[], oldParentPosition: number[]): void => {
        const allLayers = [dragLayer];
        function getLayers(children) {
            children.forEach((child) => {
                allLayers.push(child);

                if (child.children && child.children.length) {
                    getLayers(child.children);
                }
            });
        }

        /**
         * Get all child layers of the drag layer.
         */
        if (dragLayer.children && dragLayer.children.length) {
            getLayers(dragLayer.children);
        }

        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const duration = getTemplateData<Template['frameTypes']>('frameTypes').find((frame) => frame.key === frameType)?.duration ?? 0;

        const formatToUpdate = FormatHelpers.currentFormat();

        allLayers.forEach((layer) => {
            const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(layer.key, undefined, [formatToUpdate], layerProperties);
            /**
             * Only move the timestamps when the start and end position has changed.
             * Otherwise only the length is moved.
             */
            const visibilityStartPosition = layerProps.visibility ?? DEFAULT_VISIBILITY;
            let timestamps = layerProps.animations;

            if (timestamps === undefined) timestamps = {};
            const oldVisibilityStart = layerProps.visibility?.[0];
            const oldVisibilityEnd = layerProps.visibility?.[1];

            if (
                oldVisibilityStart === undefined ||
                oldVisibilityEnd === undefined ||
                oldParentPosition === undefined ||
                oldParentPosition[0] === undefined ||
                oldParentPosition[1] === undefined
            )
                return;

            const oldLength = (oldVisibilityEnd ?? 0) - (oldVisibilityStart ?? 0);
            const oldParentLength = oldParentPosition[1] - oldParentPosition[0];
            const sameAsParent = oldLength === oldParentLength && oldVisibilityStart === oldParentPosition[0] && oldVisibilityEnd === oldParentPosition[1];

            const parentMoved = (() => {
                if (newParentPosition[0] === oldParentPosition[0] && newParentPosition[1] !== oldParentPosition[1]) return 'end';
                if (newParentPosition[0] !== oldParentPosition[0] && newParentPosition[1] === oldParentPosition[1]) return 'start';
                if (newParentPosition[0] !== oldParentPosition[0] && newParentPosition[1] !== oldParentPosition[1]) return 'both';
                return 'nothing';
            })();
            // If the position and width are not the same as parent only move it like the parent based on distance
            if (!sameAsParent && parentMoved === 'both') {
                const moveDistance = oldParentPosition[0] - newParentPosition[0];
                const newVisibility = [oldVisibilityStart - moveDistance, oldVisibilityEnd - moveDistance];

                if (newVisibility[0] < 0) {
                    newVisibility[1] = newVisibility[1] - newVisibility[0];
                    newVisibility[0] = 0;
                }
                if (newVisibility[1] > 1) {
                    newVisibility[0] = newVisibility[0] - (newVisibility[1] - 1);
                    newVisibility[1] = 1;
                }

                set(layerProperties, `${formatToUpdate}.${frameType}.${layer.key}.visibility`, newVisibility);

                if (parentMoved === 'both') {
                    const newTimestamps = this.moveTimestamps(timestamps, visibilityStartPosition, newVisibility, duration);
                    set(layerProperties, `${formatToUpdate}.${frameType}.${layer.key}.animations`, newTimestamps);
                }
            }

            // If the position and width of the visibility slider is the same as the parent move and resize it like the parent
            if (sameAsParent) {
                set(layerProperties, `${formatToUpdate}.${frameType}.${layer.key}.visibility`, newParentPosition);
                if (parentMoved === 'both') {
                    const newTimestamps = this.moveTimestamps(timestamps, visibilityStartPosition, newParentPosition, duration);
                    set(layerProperties, `${formatToUpdate}.${frameType}.${layer.key}.animations`, newTimestamps);
                }
            }
        });

        TemplateDesignerStore.save(['layerProperties', layerProperties]);
    };

    /**
     * Move the active animation to the current time.
     * @param position - The position to move the animation to.
     */
    static moveAnimationToCurrentTime = (position: 'start' | 'end', changeDuration?: boolean): void => {
        const activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');
        if (activeAnimations.length === 0) return;

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentFormat = FormatHelpers.currentFormat();
        const frameDuration = FrameTypeHelpers.getFrameDuration();
        const currentTime = SceneHelpers.getSceneTime(frameDuration);
        const stamp = this.secondsToStamp(currentTime, frameDuration);
        const roundedStamp = roundToNearestDecimal(stamp, 1000);

        const changes: MultiModel = [];
        const updatedKeyframes: {
            [layerKey in Layer['key']]: {
                [key in keyof CustomAndPredefinedAnimations]: Animation[];
            };
        } = {};

        activeAnimations.forEach((activeAnimation) => {
            switch (activeAnimation.type) {
                case ActiveAnimationType.Visibility: {
                    const visibility = this.getVisibilityByActiveAnimation(activeAnimation);
                    if (!visibility) return;

                    let newVisibility: Visibility = visibility;

                    if (position === 'start') {
                        const duration = roundToNearestDecimal(visibility[1] - visibility[0], 1000);

                        newVisibility = (() => {
                            if (changeDuration) {
                                return [roundedStamp, visibility[1]];
                            } else {
                                return [roundedStamp, roundedStamp + duration];
                            }
                        })();
                    } else if (position === 'end') {
                        const duration = roundToNearestDecimal(visibility[1] - visibility[0], 1000);

                        newVisibility = (() => {
                            if (changeDuration) {
                                return [visibility[0], roundedStamp];
                            } else {
                                return [roundedStamp - duration, roundedStamp];
                            }
                        })();
                    }

                    if (newVisibility[0] < 0 || newVisibility[1] > 1) return;
                    if (newVisibility[0] === visibility[0] && newVisibility[1] === visibility[1]) return;
                    if (newVisibility[0] === newVisibility[1]) return;
                    if (newVisibility[0] > newVisibility[1] || newVisibility[1] < newVisibility[0]) return;
                    changes.push([`layerProperties.${currentFormat}.${frameType}.${activeAnimation.layer}.visibility`, newVisibility]);
                    break;
                }
                case ActiveAnimationType.PredefinedAnimation: {
                    const animation = this.getKeyframesByActiveAnimation(activeAnimation);
                    if (!animation) return;

                    if (position === 'start') {
                        const duration = roundToNearestDecimal(animation[1].stamp - animation[0].stamp, 1000);
                        animation[0].stamp = roundedStamp;

                        if (!changeDuration) {
                            animation[1].stamp = roundedStamp + duration;
                        }
                    } else if (position === 'end') {
                        const duration = roundToNearestDecimal(animation[1].stamp - animation[0].stamp, 1000);
                        animation[1].stamp = roundedStamp;

                        if (!changeDuration) {
                            animation[0].stamp = roundedStamp - duration;
                        }
                    }

                    if (animation[0].stamp < 0 || animation[1].stamp > 1) return;
                    if (animation[0].stamp === animation[1].stamp) return;
                    if (animation[0].stamp > animation[1].stamp || animation[1].stamp < animation[0].stamp) return;

                    changes.push([
                        `layerProperties.${currentFormat}.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`,
                        animation
                    ]);

                    break;
                }
                /**
                 * This case is different from the other cases.
                 * We need to update the keyframe in the animation. In order to not overwrite the previous keyframes, we need to update the whole animation.
                 * And first save it in a separate object and later create the changes for the store.
                 */
                case ActiveAnimationType.Keyframe: {
                    if (!updatedKeyframes[activeAnimation.layer]) {
                        updatedKeyframes[activeAnimation.layer] = {};
                    }

                    if (!updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? '']) {
                        updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? ''] = this.getKeyframesByActiveAnimation(activeAnimation);
                    }
                    break;
                }
            }
        });

        /**
         * Create the changes for the store when there are keyframes.
         */
        if (Object.keys(updatedKeyframes).length > 0) {
            type AnimationMap = {
                [K in keyof CustomAndPredefinedAnimations]: ActiveAnimation[];
            };

            /**
             * Group active animations by layer and animation key.
             * So it easer to find the correct keyframe later.
             */
            const activeAnimationsGroupedByLayerAndAnimation: AnimationMap = activeAnimations.reduce((all, animation) => {
                if (!all[animation.layer]) {
                    all[animation.layer] = {};
                }

                if (!all[animation.layer][animation.animationKey ?? '']) {
                    all[animation.layer][animation.animationKey ?? ''] = [];
                }

                all[animation.layer][animation.animationKey ?? ''].push(animation);
                return all;
            }, {} as AnimationMap);

            /**
             * Update the keyframes based on the active animations.
             */
            Object.keys(updatedKeyframes).forEach((layer) => {
                Object.keys(updatedKeyframes[layer]).forEach((animationKey) => {
                    let animation = updatedKeyframes[layer][animationKey];
                    const activeAnimation = activeAnimationsGroupedByLayerAndAnimation[layer][animationKey];

                    if (position === 'start') {
                        // Find most left keyframe in animation based from active animation.
                        let mostLeftKeyframe: Animation | undefined;

                        activeAnimation.forEach((activeAnimation) => {
                            const keyframe = animation.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);
                            if (!mostLeftKeyframe || keyframe.stamp < mostLeftKeyframe.stamp) {
                                mostLeftKeyframe = keyframe;
                            }
                        });

                        if (!mostLeftKeyframe) return;

                        const difference = mostLeftKeyframe.stamp - roundedStamp;
                        mostLeftKeyframe.stamp -= difference;

                        activeAnimation.forEach((activeAnimation) => {
                            if (mostLeftKeyframe?.id === activeAnimation.keyframeKey) return;
                            const otherKeyframe = animation.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);
                            if (otherKeyframe) {
                                otherKeyframe.stamp -= difference;
                            }
                        });
                    } else if (position === 'end') {
                        // Find most right keyframe in animation based from active animation.
                        let mostRightKeyframe: Animation | undefined;

                        activeAnimation.forEach((activeAnimation) => {
                            const keyframe = animation.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);
                            if (!mostRightKeyframe || keyframe.stamp > mostRightKeyframe.stamp) {
                                mostRightKeyframe = keyframe;
                            }
                        });

                        if (!mostRightKeyframe) return;

                        const difference = mostRightKeyframe.stamp - roundedStamp;
                        mostRightKeyframe.stamp -= difference;

                        activeAnimation.forEach((activeAnimation) => {
                            if (mostRightKeyframe?.id === activeAnimation.keyframeKey) return;
                            const otherKeyframe = animation.find((keyframe) => keyframe.id === activeAnimation.keyframeKey);
                            if (otherKeyframe) {
                                otherKeyframe.stamp -= difference;
                            }
                        });
                    }

                    animation.sort((a, b) => a.stamp - b.stamp);
                    animation = this.checkSameTimestamp(animation);

                    changes.push([`layerProperties.${currentFormat}.${frameType}.${layer}.animations.${animationKey}`, animation]);
                });
            });
        }

        TemplateDesignerStore.save(changes);
    };

    /**
     * Move the active animation with the given amount.
     * @param direction - The direction to move the animation.
     * @param amount - The amount to move the animation.
     */
    static moveAnimation = (direction: 'left' | 'right', amount: number): void => {
        const activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');
        if (activeAnimations.length === 0) return;

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentFormat = FormatHelpers.currentFormat();
        const frameDuration = FrameTypeHelpers.getFrameDuration();
        const newAmount = this.secondsToStamp(amount, frameDuration);

        const changes: MultiModel = [];
        const updatedKeyframes: {
            [layerKey in Layer['key']]: {
                [key in keyof CustomAndPredefinedAnimations]: Animation[];
            };
        } = {};

        activeAnimations.forEach((activeAnimation) => {
            switch (activeAnimation.type) {
                case ActiveAnimationType.Visibility: {
                    const visibility = this.getVisibilityByActiveAnimation(activeAnimation);
                    if (!visibility) return;

                    const newVisibility: Visibility = (() => {
                        if (direction === 'left') {
                            return [visibility[0] - newAmount, visibility[1] - newAmount];
                        } else {
                            return [visibility[0] + newAmount, visibility[1] + newAmount];
                        }
                    })();

                    changes.push([`layerProperties.${currentFormat}.${frameType}.${activeAnimation.layer}.visibility`, newVisibility]);
                    break;
                }
                case ActiveAnimationType.PredefinedAnimation: {
                    const animation = this.getKeyframesByActiveAnimation(activeAnimation);
                    if (!animation) return;

                    const newAnimation = animation.map((keyframe) => {
                        keyframe.stamp = direction === 'left' ? keyframe.stamp - newAmount : keyframe.stamp + newAmount;
                        return keyframe;
                    });

                    changes.push([
                        `layerProperties.${currentFormat}.${frameType}.${activeAnimation.layer}.animations.${activeAnimation.animationKey}`,
                        newAnimation
                    ]);
                    break;
                }
                /**
                 * This case is different from the other cases.
                 * We need to update the keyframe in the animation. In order to not overwrite the previous keyframes, we need to update the whole animation.
                 * And first save it in a separate object and later create the changes for the store.
                 */
                case ActiveAnimationType.Keyframe: {
                    if (!updatedKeyframes[activeAnimation.layer]) {
                        updatedKeyframes[activeAnimation.layer] = {};
                    }

                    if (!updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? '']) {
                        updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? ''] = this.getKeyframesByActiveAnimation(activeAnimation);
                    }

                    const animation = updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? ''];
                    if (!animation) return;

                    const newAnimation = animation.map((keyframe) => {
                        if (keyframe.id === activeAnimation.keyframeKey) {
                            keyframe.stamp = direction === 'left' ? keyframe.stamp - newAmount : keyframe.stamp + newAmount;
                            keyframe.stamp = roundToNearestDecimal(keyframe.stamp, 1000);
                        }
                        return keyframe;
                    });

                    updatedKeyframes[activeAnimation.layer][activeAnimation.animationKey ?? ''] = newAnimation;
                    break;
                }
            }
        });

        /**
         * Create the changes for the store when there are keyframes.
         */
        if (Object.keys(updatedKeyframes).length > 0) {
            Object.keys(updatedKeyframes).forEach((layer) => {
                Object.keys(updatedKeyframes[layer]).forEach((animationKey) => {
                    changes.push([
                        `layerProperties.${currentFormat}.${frameType}.${layer}.animations.${animationKey}`,
                        this.checkSameTimestamp(updatedKeyframes[layer][animationKey])
                    ]);
                });
            });
        }

        TemplateDesignerStore.save(changes);
    };

    /**
     * Select all animations depending on the selected layer and existing selected animations.
     */
    static selectAnimations = (): void => {
        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers', { clone: false })[0];
        if (!selectedLayer) return;

        const activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');

        const currentFormat = FormatHelpers.currentFormat();
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        // Get the layer properties without cloning them, we don't need to clone them since we are not changing them.
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties', { clone: false });

        const newActiveAnimations: State['activeAnimations'] = [];

        /**
         * Get all keyframe animations from the selected layer.
         * If there are keyframes, we want to select all keyframes from the selected layer.
         */
        const keyframeAnimations = activeAnimations.filter(
            (activeAnimation) => activeAnimation.layer === selectedLayer.key && activeAnimation.type === ActiveAnimationType.Keyframe
        );

        if (keyframeAnimations.length) {
            const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(selectedLayer.key, frameType, [currentFormat], layerProperties);

            // Filter keyframe animations on unique animation keys.
            const uniqueAnimationKeys = keyframeAnimations.map((activeAnimation) => activeAnimation.animationKey);
            const uniqueAnimationKeysSet = new Set(uniqueAnimationKeys);

            uniqueAnimationKeysSet.forEach((animationKey) => {
                animationKey &&
                    layerProps?.animations?.[animationKey]?.forEach((keyframe) => {
                        newActiveAnimations.push({
                            type: ActiveAnimationType.Keyframe,
                            layer: selectedLayer.key,
                            animationKey: animationKey as keyof CustomAndPredefinedAnimations,
                            keyframeKey: keyframe.id
                        });
                    });
            });
        }

        /**
         * Get all predefined animations from the selected layer.
         * If there are predefined animations, we want to select all predefined animations from the selected layer.
         */
        const predefinedAnimations = activeAnimations.filter(
            (activeAnimation) => activeAnimation.layer === selectedLayer.key && activeAnimation.type === ActiveAnimationType.PredefinedAnimation
        );

        if (predefinedAnimations.length) {
            const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(selectedLayer.key, frameType, [currentFormat], layerProperties);

            Object.keys(layerProps?.animations ?? {}).forEach((animationKey) => {
                const isPredefinedAnimation = this.isPredefinedAnimation(animationKey as keyof CustomAndPredefinedAnimations);

                if (isPredefinedAnimation) {
                    const keyframe = layerProps?.animations?.[animationKey]?.[0];

                    newActiveAnimations.push({
                        type: ActiveAnimationType.PredefinedAnimation,
                        layer: selectedLayer.key,
                        animationKey: animationKey as keyof CustomAndPredefinedAnimations,
                        keyframeKey: keyframe.id
                    });
                }
            });
        }

        /**
         * If there are no keyframes or predefined animations, we want to select all animations from the parent layer.
         */
        if (keyframeAnimations.length === 0 && predefinedAnimations.length === 0) {
            const parentSelectedLayer = LayerHelpers.findLayerParent(selectedLayer.key) ?? selectedLayer;

            /**
             * Get all active animations from the parent layer.
             * @param layers - Layers to get the new active animations from.
             */
            const getNewActiveAnimations = (layers: Layer[]): void => {
                layers.forEach((layer) => {
                    if (layer.children) getNewActiveAnimations(layer.children);

                    const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(layer.key, frameType, [currentFormat], layerProperties);

                    if (!layerProps || !layerProps.animations) return;

                    /**
                     * Select all animations for the current layer.
                     */
                    Object.keys(layerProps.animations).forEach((animationKey) => {
                        const animation = layerProps.animations?.[animationKey];
                        const isPredefinedAnimation = this.isPredefinedAnimation(animationKey as keyof CustomAndPredefinedAnimations);

                        animation.forEach((keyframe) => {
                            newActiveAnimations.push({
                                type: isPredefinedAnimation ? ActiveAnimationType.PredefinedAnimation : ActiveAnimationType.Keyframe,
                                layer: layer.key,
                                animationKey: animationKey as keyof CustomAndPredefinedAnimations,
                                keyframeKey: keyframe.id
                            });
                        });
                    });

                    // Select the visibility.
                    if (layerProps.visibility) {
                        newActiveAnimations.push({
                            type: ActiveAnimationType.Visibility,
                            layer: layer.key
                        });
                    }
                });
            };

            parentSelectedLayer.children && getNewActiveAnimations(parentSelectedLayer.children);
        }

        // Filter active animation duplicates.
        const filteredActiveAnimations = newActiveAnimations.filter(
            (activeAnimation, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.layer === activeAnimation.layer && t.animationKey === activeAnimation.animationKey && t.keyframeKey === activeAnimation.keyframeKey
                )
        );

        TemplateDesignerStore.save(['state.activeAnimations', filteredActiveAnimations]);
    };

    /**
     * Checks if the layer has an input and an animation that are conflicting e.g. a color input and a color animation and saves it in conflictingAnimations state
     * @param layer - The layer to check
     * @param animationKey - The animation key to check e.g. color, backgroundColor or shadow
     */
    static checkConflictingAnimations = (layerKey: Layer['key'], animationKey: keyof NonNullable<LayerProperties['animations']>): void => {
        const conflicingProperties = ['color', 'backgroundColor', 'shadow'];
        if (!conflicingProperties.includes(animationKey)) return;

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const interfacePropertyKey = (() => {
            switch (animationKey) {
                case 'shadow': {
                    return 'shadowColor';
                }
                default: {
                    return animationKey;
                }
            }
        })();

        const hasConflictingAnimation = DynamicLayerHelpers.checkDynamicLayersForAttribute(layerKey, interfacePropertyKey);

        if (!hasConflictingAnimation) return;

        let conflictingAnimations = getTemplateData<State['conflictingAnimations']>(`state.conflictingAnimations`);
        if (!conflictingAnimations) conflictingAnimations = {};
        if (!conflictingAnimations?.[frameType]?.[layerKey]) {
            set(conflictingAnimations, `${frameType}.${layerKey}`, []);
        }

        TemplateDesignerStore.save([`state.conflictingAnimations.${frameType}.${layerKey}`, [...conflictingAnimations[frameType][layerKey], animationKey]], {
            saveToHistory: false
        });
    };

    /**
     * Sort the keyframes based on the stamp.
     * @param keyframes - Keyframes to sort.
     * @returns Sorted keyframes.
     */
    static sortKeyframes = (keyframes: Animation[]): Animation[] => {
        return keyframes.sort((a, b) => a.stamp - b.stamp);
    };

    /**
     * Check if there are keyframes with the same timestamp.
     * If there are keyframes with the same timestamp, we want to adjust the timestamp.
     * @param keyframes - Keyframes to check.
     * @returns Adjusted keyframes with the same timestamp.
     */
    static checkSameTimestamp = (keyframes: Animation[]): Animation[] => {
        const newKeyframes = cloneDeep(keyframes);
        const sortedKeyframes = this.sortKeyframes(newKeyframes);

        for (let i = 0; i < sortedKeyframes.length - 1; i++) {
            if (sortedKeyframes[i].stamp === sortedKeyframes[i + 1].stamp) {
                sortedKeyframes[i + 1].stamp += 0.01;
            }
        }

        return sortedKeyframes;
    };

    /**
     * Check if there are keyframes outside the timeline.
     * @param keyframes - Keyframes to check.
     * @returns If there are keyframes outside the timeline.
     */
    static hasKeyframesOutside = (keyframes: Animation[]): boolean => {
        return keyframes.some((keyframe) => keyframe.stamp < 0 || keyframe.stamp > 1);
    };

    /**
     * Check if the visibility is outside of the timeline.
     * @param visibility - Visibility to check.
     * @returns If the visibility is outside the timeline.
     */
    static hasVisibilityOutside = (visibility: Visibility): boolean => {
        return visibility.some((stamp) => stamp < 0 || stamp > 1);
    };

    /**
     * Check if the timeline is open.
     * @param timelineHeight - The height of the timeline.
     * @returns If the timeline is open.
     */
    static isTimelineOpen = (timelineHeight: number): boolean => {
        return timelineHeight > HIDE_TIMELINE_HEIGHT;
    };

    /**
     * Translate a timestamp from one duration to another based on new duration of predefined animation
     * @param stamp - The timestamp that needs to be translated.
     * @param offset - The offset of the predefined animation.
     * @param totalDuration - The total duration of the animation.
     * @param newTotalDuration - The new total duration of the animation.
     * @returns The translated timestamp.
     */
    static translateTimestamp = (stamp: number, offset: number, totalDuration: number, newTotalDuration: number): number => {
        const newStamp: number = (stamp / totalDuration) * newTotalDuration + offset;
        return newStamp;
    };
}

export { TimelineHelpers };
