import ViewState from 'components/data/ViewState';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { isAMV2Enabled } from 'components/template-management/utilities/isAmV2Enabled';
import cssStringToObject from '../components/canvas/utils/cssStringToObject';
import emptyTemplate from '../config/empty-template';
import { DEFAULT_FORMAT_ORDER, OPEN_ON_MOUNT_KEY } from '../constants';
import Layer from '../types/layer.type';
import Template, { BannerLoopOptions, CurrentView, ExportForPlatform, RightSidebarTab } from '../types/template.type';
import { SHOW_LAYOUT_GRID, SHOW_RULERS, SHOW_TIMELINE } from '../config/defaultValues';
import { getTemplateData } from './data.helpers';
import FormatHelpers from './format.helpers';

class TemplateDesignerHelpers {
    /**
     * Parses data coming from the api, to data Template Designer can read.
     * @param data - Data from the api.
     * @returns Template Designer data.
     */
    static parseTemplateDesignerData = (data: TDTemplateAsset): Template => {
        const showRulers = (() => {
            const value = ViewState.get('templateDesigner', 'showRulers');
            if (value === undefined) return SHOW_RULERS;
            return value;
        })();
        const showLayoutGrid = (() => {
            const value = ViewState.get('templateDesigner', 'showLayoutGrid');
            if (value === undefined) return SHOW_LAYOUT_GRID;
            return value;
        })();
        const showTimeline = (() => {
            const value = ViewState.get('templateDesigner', 'timelineHeight');
            if (value === undefined) return SHOW_TIMELINE;
            return value > 60;
        })();

        const brands = (() => {
            if (isAMV2Enabled()) {
                return data.labels?.brands ?? [];
            }
            return data.data?.brand ? [data.data?.brand ?? ''] : [];
        })();

        if (!data.data.templateSetup || Object.keys(data.data.templateSetup).length === 0) {
            return {
                ...emptyTemplate,
                id: data._id,
                templateData: {
                    ...emptyTemplate.templateData,
                    title: data.title ?? emptyTemplate.templateData.title,
                    type: data.subType,
                    identifier: data.data.identifier,
                    settings: {
                        ...(data.data.settings ? data.data.settings : emptyTemplate.templateData.settings),
                        dynamicFrames: data.subType === 'displayAdDesigned'
                    },
                    ...(isAMV2Enabled()
                        ? {
                              version: data.version || 0,
                              releasedBy: data.releasedBy,
                              releasedAt: data.releasedAt,
                              releasedVersion: data.releasedVersion,
                              createdDate: data.createdAt,
                              updatedDate: data.updatedAt,
                              status: data.status === 'available' ? 'available' : 'draft',
                              brands
                          }
                        : {})
                },
                view: {
                    ...emptyTemplate.view,
                    showRulers,
                    showLayoutGrid,
                    showTimeline
                }
            };
        }

        const template: Template = {
            ...data.data.templateSetup,
            id: data._id,
            url: emptyTemplate.url,
            dynamicLayers: data.data.templateSetup.dynamicLayers ?? emptyTemplate.dynamicLayers,
            templateData: {
                title: data.title,
                identifier: data.data.identifier,
                description: data.data.description || null,
                brands: brands,
                type: data.subType,
                status: data.status === 'available' ? 'available' : 'draft',
                settings: data.data.settings ?? emptyTemplate.templateData.settings,
                image: data.data.image || '',
                version: data.version || 0,
                releasedBy: data.releasedBy,
                releasedAt: data.releasedAt,
                releasedVersion: data.releasedVersion,
                createdDate: data.createdAt,
                updatedDate: data.updatedAt,
                customData: data.customData,
                public: data.public,
                thumbnail: data.data.thumbnail
            },
            designerSettings: {
                ...data.data.templateSetup.designerSettings,
                disableBase: data.data.templateSetup.designerSettings.disableBase ?? false,
                customFormats: data.data.templateSetup.designerSettings.customFormats || [],
                autoSave: data.status === 'available' ? false : data.data.templateSetup.designerSettings.autoSave ?? false,
                enableAnimations:
                    data.data.templateSetup.designerSettings.enableAnimations === undefined ? true : data.data.templateSetup.designerSettings.enableAnimations,
                enableLottie: data.data.templateSetup.designerSettings.enableLottie || false,
                exportForPlatform: data.data.templateSetup.designerSettings.exportForPlatform || ExportForPlatform.General,
                positionByLanguage: data.data.templateSetup.designerSettings.positionByLanguage || false,
                customCSS: data.data.templateSetup.designerSettings.customCSS || false,
                customJS: data.data.templateSetup.designerSettings.customJS || false,
                gridSize: data.data.templateSetup.designerSettings.gridSize === undefined ? 8 : data.data.templateSetup.designerSettings.gridSize,
                bannerLoop: data.data.templateSetup.designerSettings.bannerLoop || BannerLoopOptions.NoLoop,
                highlightedCharacter: data.data.templateSetup.designerSettings.highlightedCharacter || '*',
                backgroundbackupImageWaitTime: data.data.templateSetup.designerSettings.backgroundbackupImageWaitTime
            },
            view: {
                frameType: data.data.templateSetup.frameTypes[data.data.templateSetup.frameTypes.length - 1].key,
                currentView: CurrentView.Design,
                showRulers,
                showLayoutGrid,
                showTimeline,
                hideLeftSidebar: false,
                showLayerPanel: false,
                showFormatManagementDialog: false,
                testEnvironment: {
                    open: false,
                    uuid: '',
                    dataModel: ''
                },
                showVersionHistory: false,
                showComments: false,
                showTab: RightSidebarTab.LayerEdit,
                showHelpPanel: false
            },
            state: {
                isPlaying: false,
                openItemTree: false,
                isUploading: false,
                selectedLayers: [],
                activeAnimations: [],
                displayFormats: (() => {
                    const maxVisibibleFormats = FormatHelpers.getMaxVisibleFormats();
                    const displayFormats =
                        data.data.templateSetup.state?.displayFormats ?? data.data?.templateSetup?.formats?.map((format) => format.key) ?? [];

                    return displayFormats.slice(0, maxVisibibleFormats);
                })(),
                conflictingAnimations: data.data.templateSetup.state?.conflictingAnimations || null,
                largeMedia: data.data.templateSetup.state?.largeMedia,
                selectedFormats: ['general'],
                selectedFormatSets: data.data.templateSetup.state?.selectedFormatSets ?? [],
                formatOrder: data.data.templateSetup.state?.formatOrder ?? DEFAULT_FORMAT_ORDER,
                restoreVersion: null,
                timelineHeight: 0,
                leftSidebarWidth: 0,
                formatsWithAudio: data.data.templateSetup.formats.length ? [data.data.templateSetup.formats[0].key] : [], // Only play the audio of first format (if available). Otherwise multiple formats will be playing audio.
                changedAfterSave: false,
                lastUsedFont: data.data.templateSetup.state?.lastUsedFont
            },
            templateSetup: {
                ...(data.data.templateSetup.templateSetup ?? {}),
                templateVersion:
                    (data.data.templateSetup && data.data.templateSetup.templateSetup && data.data.templateSetup.templateSetup.templateVersion) || '1'
            }
        };

        return template;
    };

    /**
     * Check if this is valid CSS.
     * Checking by total lines in string and total lines from the object.
     * If they do not match, then it is not valid CSS.
     * Because if if a CSS in the stirng is not valid, then it will not be in the object.
     * @param css - CSS string.
     * @returns {boolean} - Returns true if the CSS string is valid.
     */
    static isValidCSS = (css: string): boolean => {
        css = css.replace(/\n/g, '');
        if (css === '') return true;
        const totalLines = css.split(';').filter((row) => row !== '').length;
        const customCssLines = Object.keys(cssStringToObject(css).attributes).length;
        return totalLines === customCssLines;
    };

    /**
     * Checks session storage if the specific layer already has shown a upload dialog. If not, return true.
     * @param layerKey - Layer key of the current layer.
     * @returns If there is a value it returns false.
     */
    static checkAssetGalleryInputOpenedBefore = (layerKey: Layer['key']): boolean => {
        const openOnMount = sessionStorage.getItem(OPEN_ON_MOUNT_KEY + layerKey);
        return !openOnMount;
    };

    /**
     * Saves layer key in session storage so it won't open next time.
     * @para layerKey - Layer key of the current layer.
     */
    static setAssetGalleryInputOpenedBefore = (layerKey: Layer['key']): void => {
        sessionStorage.setItem(OPEN_ON_MOUNT_KEY + layerKey, 'false');
    };

    /**
     * Get the current view of TD from the component store.
     * @returns The current view.
     */
    static getCurrentView = (): CurrentView => {
        return getTemplateData<CurrentView>('view.currentView');
    };
}

export { TemplateDesignerHelpers };
